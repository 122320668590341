import { usePathname } from 'next/navigation'
import { Home } from 'lucide-react'

import { menusConfig } from '@/config/menu.config'

import { BreadcrumbItem, Breadcrumbs } from '../ui/breadcrumbs'

const TopNavigator = () => {
  const pathname = usePathname()
  const breadcrumbItems = pathname.split('/').filter(Boolean)
  const menus = menusConfig

  const isLastItemOrNoHref = (item: string, index: number) => {
    return (
      index === breadcrumbItems.length - 1 ||
      menus.filter(
        (menu) =>
          menu.title.toLowerCase() === item.replace(/-/g, ' ') && !menu.href,
      ).length > 0
    )
  }

  return (
    <>
      <Breadcrumbs className="font-bold">
        <BreadcrumbItem href="/">
          <Home className="size-4" />
        </BreadcrumbItem>
        {breadcrumbItems.map((item, i) => {
          const isDisabled = isLastItemOrNoHref(item, i)
          return (
            <BreadcrumbItem
              key={i}
              noCursor={isDisabled}
              href={
                isDisabled
                  ? undefined
                  : `/${breadcrumbItems.slice(0, i + 1).join('/')}`
              }
              className="select-none text-xs capitalize text-default-600">
              {item.replace(/-/g, ' ')}
            </BreadcrumbItem>
          )
        })}
      </Breadcrumbs>
    </>
  )
}

export default TopNavigator
