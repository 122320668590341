import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

// NOTE: keep this for future implementations
interface UserStoreState {
  currency: string
  setCurrency: (currency: string) => void
  timezone: string
  setTimezone: (timezone: string) => void
}

export const useUserStore = create<UserStoreState>()(
  persist(
    (set) => ({
      currency: 'AUD',
      setCurrency: (currency) => set({ currency }),
      timezone: 'Australia/Sydney',
      setTimezone: (timezone) => set({ timezone }),
    }),
    { name: 'user-store', storage: createJSONStorage(() => localStorage) },
  ),
)
