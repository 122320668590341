import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryDevtools"] */ "/opt/buildhome/repo/node_modules/@tanstack/react-query-devtools/build/modern/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["NuqsAdapter"] */ "/opt/buildhome/repo/node_modules/nuqs/dist/adapters/next/app.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/opt/buildhome/repo/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/scss/globals.scss");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/simplebar-react/dist/simplebar.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["SonnToaster"] */ "/opt/buildhome/repo/src/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/opt/buildhome/repo/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/src/providers/dashboard-layout.provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/src/providers/react-query.provider.tsx");
